/*
  This function serves as a container to organize functions that will run after nearby now has been loaded.
  To trigger this function outside an observer simply pass null for both arguments. adjustNearbyNow(null, null)
 */
function adjustNearbyNow(mutationsList, observer) {

  adjustCheckins();

  adjustNearbyNowTitle()

  if (observer !== null) {
    observer.disconnect();
  }

}

/*
  Re-organizes the contents of the checkins to better display images and videos.
 */
function adjustCheckins() {
  const checkins = document.querySelectorAll('.nn-samap-cont');
  for (let i = 0; i < checkins.length; i++) {
    const currentCheckin = checkins[i];
    const samapEl = currentCheckin.firstElementChild;
    const newParent = document.createElement('div');
    newParent.classList.add('checkinBody');

    let checkinChildren = samapEl.childElementCount;

    for (let j = 0; j < checkinChildren; j++) {
      if (samapEl.firstElementChild.tagName !== "A" && !samapEl.firstElementChild.classList.contains('nn-samap-ci-video-container')) {
        const moveThis = samapEl.removeChild(samapEl.firstElementChild);
        newParent.appendChild(moveThis);
      }
    }

    currentCheckin.firstElementChild.prepend(newParent);

    if (samapEl.lastElementChild.tagName === "A" || samapEl.lastElementChild.classList.contains('nn-samap-ci-video-container')) {
      samapEl.classList.add('containsMedia');
    }
  }
}

/*
  Changes default text of the h2 that is positioned above the map sent by nearby now
 */
function adjustNearbyNowTitle() {
  const nearbyNowTitle = document.querySelector('.nn-review-inner-cont > h2')

  const newNearbyNowTitle = nearbyNowTitle.innerHTML.replace('Local Reviews', 'Local Work and Reviews')

  nearbyNowTitle.innerHTML = newNearbyNowTitle
}

/*
  EventListener that ensures the NearbyNow code has been loaded before triggering the layout adjustments.
 */
window.addEventListener('load', function() {
  const nnContainer = document.querySelector('.nn-container');

  if (nnContainer) {
    if (document.querySelector('.nn-review-outer-cont')) {
      adjustNearbyNow(null,null);
    } else {
      const nnObserver = new MutationObserver(adjustNearbyNow);
      const nnObserverConfig = {attributes: false, childList:true,subtree:false}
      nnObserver.observe(nnContainer, nnObserverConfig);
    }
  }
})
